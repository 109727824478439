<script lang="ts" setup>
import horizontalLogo from '~/assets/icons/logo/horizontal.svg?url';
import image from '~/assets/images/centre_banner_background.png';

const SLIDE_WIDTH = 1742;
const SLIDE_GAP = 44;

const { t } = useI18n();

const isActiveSlider = ref(true);
const slideCount = ref(1);
const slider = ref<HTMLDivElement>();

const onResize = () => {
  slideCount.value =
    Math.ceil(window.innerWidth / (SLIDE_WIDTH + SLIDE_GAP)) + 1;
};

onMounted(() => {
  const onNext = () => {
    requestAnimationFrame(() => {
      if (slider.value) {
        slider.value.scrollLeft += 1;
        if (slider.value.scrollLeft >= SLIDE_WIDTH + SLIDE_GAP) {
          slider.value.scrollLeft -= SLIDE_WIDTH + SLIDE_GAP;
        }
      }

      if (isActiveSlider.value) onNext();
    });
  };

  onResize();
  onNext();

  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  isActiveSlider.value = false;

  window.removeEventListener('resize', onResize);
});
</script>

<template>
  <section class="emptyBanner">
    <div class="emptyBanner__block">
      <NuxtImg
        format="webp"
        :sizes="IMAGE_SIZES.fullscreen"
        class="emptyBanner__background"
        :src="image"
        alt="Don’t Touch My Skin"
        width="2800"
        height="1452"
      />
    </div>

    <div class="emptyBanner__slider" ref="slider">
      <img
        v-for="index in slideCount"
        :key="index"
        :src="horizontalLogo"
        width="1742"
        height="72"
        :alt="t('horizontal_logo')"
        class="emptyBanner__item"
      />
    </div>
  </section>
</template>

<i18n>
ru:
  horizontal_logo: Горизонтальный логотип сайта

en:
  horizontal_logo: Horizontal site logo
</i18n>

<style lang="scss">
.emptyBanner {
  &__block {
    padding: 20px;

    @include mq('sm') {
      padding: 20px 16px 24px;
    }
  }

  &__background {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
  }

  &__slider {
    padding: 5px 0;
    overflow: hidden;
    display: flex;
    gap: 44px;
    user-select: none;
    pointer-events: none;

    @include mq('sm') {
      gap: 20px;
      padding: 0;
    }
  }

  &__item {
    height: 72px;

    @include mq('sm') {
      height: 32px;
    }
  }
}
</style>
